<template>
	<v-card :loading="loadingParent" max-width="800" class="mx-auto" rounded="xl">
		<v-card-title>
			<span>{{ $t('offers.benefitsTitle') }}</span>
		</v-card-title>
		<v-card-text>
			<p><v-icon>mdi-currency-eur</v-icon> {{ offer.info.salary }}</p>
			<v-row>
				<v-col>
					<span>{{ $t('offers.thesisOptions') }}</span>
					<v-chip :ripple="false" v-for="thesisOption in offer.info.thesisOptions" :key="thesisOption.key" class="mx-1">
						{{ thesisOption.name }}
					</v-chip>
				</v-col>
			</v-row>
			<p style="white-space: pre-line">
				{{ offer.info.benefits }}
			</p>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'OfferBenefits',
	props: {
		loadingParent: {
			type: Boolean,
			required: true,
		},
	},
	computed: {
		...mapGetters({
			offer: 'offers/offer',
		}),
	},
}
</script>
